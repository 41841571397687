import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import { Carousel } from '@/design-system/atoms/Carousel';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';

import styles from './ContentCarousel.module.scss';

/**
 * The content carousel displays a feature a set of content cards in a Carousel.
 */
const ContentCarousel = (props) => {
    const { className, theme, title, body, items, cta, ...otherProps } = props;

    return (
        <Theme name={theme}>
            <div className={classnames(styles['content-carousel'], className)} {...otherProps}>
                <div className={styles['content-carousel__header']}>
                    <ContentContainer>
                        {title && (
                            <Title
                                tag={Title.TAG.H2}
                                variant={Title.VARIANT.H3}
                                content={title}
                                lineClamp={{ sm: 3, lg: 1 }}
                            />
                        )}
                        {body && (
                            <Text
                                variant="t1"
                                content={body}
                                lineClamp={{ sm: 2, lg: 1 }}
                                className={styles['content-carousel__description']}
                            />
                        )}
                    </ContentContainer>
                </div>
                <Carousel.Root>
                    <Carousel.Content items={items} />
                    <Carousel.Footer>
                        {cta && (cta.href || cta.label) && (
                            <Carousel.CallToAction
                                {...cta}
                                analytics={{
                                    componentName: 'Content Carousel',
                                    componentTitle: title,
                                }}
                            />
                        )}
                        <Carousel.Spacer />
                        <Carousel.Navigation
                            analytics={{
                                componentName: 'Content Carousel',
                                componentTitle: title,
                            }}
                        />
                    </Carousel.Footer>
                </Carousel.Root>
            </div>
        </Theme>
    );
};

ContentCarousel.propTypes = {
    /**
     * Theme name to apply.
     */
    theme: PropTypes.oneOf(Object.values(Theme.NAME)),
    /**
     * Title
     */
    title: PropTypes.node,
    /**
     * Description
     */
    body: PropTypes.node,
    /**
     * Carousel items
     */
    items: PropTypes.arrayOf(PropTypes.node),
    /**
     * Carousel CTA
     */
    cta: PropTypes.shape(Button.propTypes),
};

ContentCarousel.defaultProps = {
    theme: Theme.NAME.SPECIALTIES,
};

ContentCarousel.Card = Carousel.Card;

export default ContentCarousel;
