import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '@/design-system/atoms/Button';
import Typography from '@/design-system/atoms/Typography';

import { Card } from '../Card';
import styles from './CardContent.module.scss';

export const CardContent = (props) => {
    const {
        className,
        isClickableCard = true,
        header,
        eyebrow,
        title,
        body,
        cta,
        analyticsActionLabel,
        analyticsComponentTitle,
    } = props;

    return (
        <Card.Root isClickableCard={isClickableCard}>
            <Card.Container
                className={className}
                analyticsActionLabel={analyticsActionLabel}
                analyticsComponentTitle={analyticsComponentTitle}
            >
                {header && <Card.Header>{header}</Card.Header>}
                <Card.Content>
                    {eyebrow && (
                        <Typography
                            className={styles['eyebrow']}
                            variant="e1"
                            content={eyebrow}
                            lineClamp={1}
                        />
                    )}
                    {title && (
                        <Typography
                            className={styles['title']}
                            variant="t2b"
                            content={title}
                            lineClamp={2}
                        />
                    )}
                    {body && (
                        <Typography
                            className={styles['description']}
                            variant="t2"
                            content={body}
                            lineClamp={4}
                        />
                    )}
                </Card.Content>
                <div className={styles['spacer']} />
                {cta && (
                    <Card.CallToAction
                        {...cta}
                        analyticsActionLabel={analyticsActionLabel}
                        analyticsComponentTitle={analyticsComponentTitle}
                    />
                )}
            </Card.Container>
        </Card.Root>
    );
};

CardContent.propTypes = {
    isClickableCard: PropTypes.bool,
    header: PropTypes.node,
    eyebrow: PropTypes.string,
    title: PropTypes.string,
    body: PropTypes.string,
    cta: PropTypes.shape(Button.propTypes),
    analyticsActionLabel: PropTypes.string,
    analyticsComponentTitle: PropTypes.string,
};
