import PropTypes from 'prop-types';
import React from 'react';

import { Image } from '@/design-system/atoms/Image';

import { CardContent } from '../CardContent';

export const CardImage = (props) => {
    const { image, ...otherProps } = props;

    return (
        <CardContent
            header={<Image {...image} defaultAspectRatio="16/9" mobileAspectRatio="16/9" />}
            {...otherProps}
        />
    );
};

CardImage.propTypes = {
    image: PropTypes.shape(Image.propTypes).isRequired,
    ...CardContent.propTypes,
};
