import React from 'react';

import { CardContent } from '@/design-system/atoms/Cards/CardContent';
import { CardImage } from '@/design-system/atoms/Cards/CardImage';
import UIContentCarousel from '@/design-system/molecules/ContentCarousel/ContentCarousel';
import { generateStyleModifiers } from '@/utils/generateStyleModifiers';

const ResourceCarousel = (props) => {
    const { appliedCssClassNames, title, body, items, cta } = props;
    const modifiers = generateStyleModifiers(appliedCssClassNames);
    const theme = modifiers.theme;

    const normalizeItems = React.useMemo(() => {
        return Array.isArray(items)
            ? items.map((i, index) => {
                  return (
                      <UIContentCarousel.Card key={index} fullHeight>
                          {i.assetImage ? (
                              <CardImage
                                  title={i.title}
                                  body={i.body}
                                  eyebrow={i.eyebrow}
                                  cta={{
                                      href: i.href,
                                      csr: i.csr,
                                  }}
                                  image={i.assetImage}
                                  analyticsActionLabel={i.title}
                                  analyticsComponentTitle={i.eyebrow}
                              />
                          ) : (
                              <CardContent
                                  title={i.title}
                                  body={i.body}
                                  eyebrow={i.eyebrow}
                                  cta={{
                                      href: i.href,
                                      csr: i.csr,
                                  }}
                                  analyticsActionLabel={i.title}
                                  analyticsComponentTitle={i.eyebrow}
                              />
                          )}
                      </UIContentCarousel.Card>
                  );
              })
            : [];
    }, [items]);

    return (
        <UIContentCarousel
            theme={theme}
            title={title}
            body={body}
            items={normalizeItems}
            cta={cta}
        />
    );
};

export default ResourceCarousel;
